<template>
  <div class="enti" :class="{ mobile: $vuetify.breakpoint.smAndDown }">
    <!-- page title -->
    <page-title></page-title>

    <!-- enti header -->
    <v-container>
      <div class="enti-header py-4" v-html="intestazione"></div>

      <!-- enti body -->
      <v-row>
        <v-col class="col-12 col-md-4" v-for="ente in enti" :key="ente.title">
          <enti-card :ente="ente"></enti-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageTitle from "../components/PageTitle";
import EntiCard from "../components/EntiCard";
import { getEnti, getIntestazione } from "../services/api";
export default {
  name: "Enti",
  components: {
    PageTitle,
    EntiCard,
  },
  data() {
    return {
      intestazione: "",
    };
  },
  async created() {
    this.$emit("setLoading", true);

    let entiPromise = getEnti();
    let intestazionePromise = getIntestazione("ENTI");

    if (this.$store.getters["getEntiList"].length == 0) {
      try {
        let { data } = await entiPromise;
        this.$store.dispatch("setEntiList", {
          entiList: data,
        });
      } catch (err) {
        console.log(err);
      }
    }

    try {
      let { data } = await intestazionePromise;
      // decode special characters
      var txt = document.createElement("textarea");
      txt.innerHTML = data[0].intestazione;
      this.intestazione = txt.value;
    } catch (err) {
      console.log(err);
    }

    this.$emit("setLoading", false);
  },
  computed: {
    enti() {
      return this.$store.getters["getEntiList"];
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
.enti {
  .enti-header {
    text-align: justify;
    font: normal normal normal 21px/40px Open Sans;
    letter-spacing: 0px;
  }
  .ente-title {
    font: normal normal 600 24px/30px Open Sans;
    letter-spacing: 0px;
  }
  a {
    color: #2ab5b9;
  }
}
.mobile {
  .enti-header {
    text-align: inherit;
  }
}
</style>