<template>
    <v-card height="230" class="py-8 justify-center ente-card" @click="onSearch">
      <div class="container-img">
      <img :src="baseUrl + imgUrl" class="img-generic-60" />
      </div>
      <div class="card-section pt-2">
        <v-card-title class="ente-title justify-center">{{
          titolo
        }}</v-card-title>
      </div>
       <router-link :to="route"
      ><span class="sr-only"
        >Per saperne di pi&ugrave; su {{titolo }}</span
      >
    </router-link>
    </v-card>
</template>

<script>
import { ENTI_DETAIL } from "../router/routes";

export default {
  name: "EntiCard",
  props: {
    ente: { type: Object, required: false },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  computed: {
    id() {
      return this.ente?.id ?? "";
    },
    route() {
      return { name: ENTI_DETAIL.name, params: { id: this.id } };
    },
    titolo() {
      return this.ente?.titolo ?? "";
    },
    imgUrl() {
      return this.ente?.url_relativa_img ?? "";
    },
  },
  methods: {
    onSearch() {
      this.$store.dispatch("clearSearchParams");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";


a {
  text-decoration: none;
}
.ente-title {
  font: normal normal 600 24px/30px Open Sans;
  letter-spacing: 0px;
  color: $text-dark;
  word-break: break-word;
}
.ente-card {
  position: relative;

  .container-img{
    height: 100px;

    .img-generic-60 {
        max-height: 110px;
    }
  }

  a {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
  }
}
</style>